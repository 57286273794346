import axios from "axios";
import * as FullStory from "@fullstory/browser";
import { useContext, useEffect, useState, useRef } from "react";
import { Toast } from "react-bootstrap";
import { Switch, Route, useLocation } from "react-router-dom";
import { AccountsContext } from "../../context/AccountsContext";
import { DocumentsContext } from "../../context/DocumentsContext";
import { EventsContext } from "../../context/EventsContext";
import { InvestmentsContext } from "../../context/InvestmentsContext";
import { NewsContext } from "../../context/NewsContext";
import { UIContext } from "../../context/UIContext";
import { UserContext } from "../../context/UserContext";
import { createObjectMap, groupBy } from "../../utils/array.util";
import { getCurrentDate } from "../../utils/date.util";
import { authRoutes } from "../Route/AuthRoutes";
import { SideNav } from "../SideNav/SideNav";
import { SpinnerOverlay } from "../SpinnerOverlay/SpinnerOverlay";
import { UserAvatar } from "../UserAvatar/UserAvatar";
import { handleTokenExpireError, stockImages } from "../../utils/misc.util";
import "./Layout.scss";
import { VideosContext } from "../../context/VideosContext";
import {CashFlow} from "../../utils/constants";
import { ShareholdersContext } from "../../context/ShareholdersContext";
import { BdcInvestmentsContext } from "../../context/BdcInvestmentsContext";

export const Layout = () => {
  const navRoutes = authRoutes.filter((r) => !!r.label && !!r.icon);
  const { errorMessage, setErrorMessage, setIsLoading } = useContext(UIContext);
  const {
    setInvestments,
    setCashFlows,
    setUpcomingCashFlows,
    setInvestmentsByCompany,
    setInvestmentsByAccount,
    setInvestmentsMap,
    setInvestmentValueTotals,
  } = useContext(InvestmentsContext);
  const {setBdcInvestments} = useContext(BdcInvestmentsContext)
  const { userData, setAuthenticated } = useContext(UserContext);
  const { setAccounts, setAccountsMap } = useContext(AccountsContext);
  const { setDocuments, setEntityPpms } = useContext(DocumentsContext);
  const { setShareholdersData } = useContext(ShareholdersContext)
  const { setNews, setNewsMap } = useContext(NewsContext);
  const { setEvents, setUpcomingEvents, setEventsMap, setEventTypes, setEventTypesMap } =
    useContext(EventsContext);
  const { setVideos } = useContext(VideosContext);
  const [isInitializing, setInitializing] = useState(true);
  const mainSectionRef = useRef(null);
  const location = useLocation();


  useEffect(() => {
    // Scroll to top on navigation: See `https://v5.reactrouter.com/web/guides/scroll-restoration`
    mainSectionRef.current.scrollTo(0, 0);
  }, [location.pathname])

  useEffect(() => {
    const getInitData = async () => {
      try {
        if (!userData.customer_id || ! userData.contact_id) {
          alert('Unexpected error while loading user data. Please contact support');
          setAuthenticated(false);
          return;
        }

        setIsLoading(true);
        const investmentsResp = (
          await axios.get(`/investments/${userData.customer_id}`)
        ).data;
        
        const {
          investmentData,
          fairValueTotals,
          ...rest
        } = investmentsResp;
        
        const investments = investmentData ?? rest;
        setInvestments(investments);
        setInvestmentValueTotals(fairValueTotals);

        const bdcInvestmentsResp = (
          await axios.get(`/bdc-investments/${userData.customer_id}`)
        ).data;
        
        const {
          bdcPositions,
        } = bdcInvestmentsResp;
        setBdcInvestments(bdcPositions);

        const accounts = (await axios.get(`/accounts/${userData.customer_id}`))
          .data;
        setAccounts(accounts.accounts);

        const news = (await axios.get(`/news/${userData.customer_id}`)).data;

        for (let i = 0; i < news.news.length; i++) {
          const n = news.news[i];
          n.logo_url =
            n.logo_url || `/images/${stockImages[i % stockImages.length]}`;
        }

        setNews(news.news);
        setNewsMap(createObjectMap(news.news, "id"));

        const shareholderDecks = await (await axios.get( `/documents/customerDocuments/${userData.customer_id}/shareholder-decks`)).data
        setShareholdersData(shareholderDecks)

        const eventTypes = (await axios.get('/events/types')).data;
        setEventTypes(eventTypes);
        setEventTypesMap(createObjectMap(eventTypes, 'id'));

        const events = (
          await axios.get(
            `/events/${userData.customer_id}/${userData.contact_id}`
          )
        ).data;
        setEvents(events.events);
        setEventsMap(createObjectMap(events.events, "id"));

        const upcomingEvents = events.events.filter(
          (e) => new Date(e.start_time).getTime() > new Date().getTime()
        );
        setUpcomingEvents(upcomingEvents);

        const documents = (
          await axios.get(
            `/documents/customerDocuments/${userData.customer_id}`
          )
        ).data;
        setDocuments(documents.documents);

        const entityPpms = (await axios.get("/documents/entityPPMs")).data;
        setEntityPpms(entityPpms.entityPPMs);

        getUpcomingCashFlows(investments.investments);

        const groupedByCompany = groupBy(
          investments.investments,
          (i) => i.investment_company_id
        );
        setInvestmentsByCompany(groupedByCompany);

        const groupedByAccount = groupBy(
          investments.investments,
          (i) => i.account_id
        );
        setInvestmentsByAccount(groupedByAccount);
        setInvestmentsMap(createObjectMap(investments.investments, "id"));
        setAccountsMap(createObjectMap(accounts.accounts, "id"));
        const videos = (await axios.get("/videos")).data;
        setVideos(videos);

        if (process.env.REACT_APP_NODE_ENV === "production") {
          FullStory.identify("", {
            displayName: `${userData.first_name} ${userData.last_name}`,
            email: userData.email,
          });
        }
      } catch (error) {
        handleTokenExpireError(error);
        setErrorMessage("Error initializing application");
        if (error.response) {
          if (error.response.data.expiredAt) {
            setAuthenticated(false);
          }
        } 
      } finally {
        setIsLoading(false);
        setInitializing(false);
      }
    };

    getInitData();
  }, []);

  const getUpcomingCashFlows = (investments) => {
    const cashFlows = investments.flatMap((i) =>
      i.cash_flows.map((cf) => ({ ...cf, investmentName: i.investment_name }))
    );
    const upcomingCapitalCalls = cashFlows.filter(
      (cf) => new Date(cf.date).getTime() >= getCurrentDate().getTime() &&
      cf.cash_flow_type_id === CashFlow.CapitalCall
    );
    setCashFlows(cashFlows);
    setUpcomingCashFlows(upcomingCapitalCalls);
  };

  return (
    <div className="bip-capital-cc-layout-wrapper">
      <SpinnerOverlay />

      <Toast
        show={!!errorMessage}
        onClose={() => setErrorMessage("")}
        delay={10000}
        autohide
      >
        <Toast.Header>
          <span className="mr-auto">
            <i
              className="fas fa-exclamation-triangle"
              style={{ color: "var(--bs-danger)" }}
            ></i>
            Error
          </span>
        </Toast.Header>
        <Toast.Body>{errorMessage}</Toast.Body>
      </Toast>

      <SideNav routes={navRoutes} />
      <main className="bip-capital-cc-layout-main" ref={mainSectionRef}>
        <UserAvatar />
        {!isInitializing ? (
          <Switch>
            {authRoutes.map((r) => (
              <Route
                path={r.path}
                exact={r.exact}
                component={r.component}
                key={r.path}
              />
            ))}
          </Switch>
        ) : (
          <p>Loading...</p>
        )}
      </main>
    </div>
  );
};
