import React, { useContext, useEffect, useState } from "react";
import { InvestmentsContext } from "../../context/InvestmentsContext";
import { Col, Container, Row } from "react-bootstrap";
import { CardStack } from "../../components/CardStack/CardStack";
import { CashFlowCard } from "../../components/CashFlowCard/CashFlowCard";
import { InvestmentsTable } from "../../components/InvestmentsTable/InvestmentsTable";
import { BdcInvestmentsTable } from "../../components/BdcInvestmentsTable/BdcInvestmentsTable";
import { NewsContext } from "../../context/NewsContext";
import "./InvestmentsPage.scss";
import { NewsCard } from "../../components/NewsCard/NewsCard";
import { AccountsContext } from "../../context/AccountsContext";
import { CashFlowsTable } from "../../components/CashFlowsTable/CashFlowsTable";
import { Link, Route, Switch } from "react-router-dom";
import { InvestmentDetailsPage } from "./InvestmentDetails";
import { UserContext } from "../../context/UserContext";
import { logUserAction, UserAction } from "../../utils/logging.util";
import { AggregateAccountDisclosure } from "../../components/Disclosure/AggregateAccountDisclosure";
import { PerformanceDisclosure } from "../../components/Disclosure/PerformanceDisclosure";
import { BdcInvestmentsContext } from "../../context/BdcInvestmentsContext";
import {BdcPositionDetails} from "./BdcPositionDetails";

export default function InvestmentsPage() {
  const { investments, upcomingCashFlows } = useContext(InvestmentsContext);
  const { accounts, accountsMap } = useContext(AccountsContext);
  const {bdcInvestments} = useContext(BdcInvestmentsContext);
  const { news } = useContext(NewsContext);
  const {userData} = useContext(UserContext);
  const [showByAccount, setShowByAccount] = useState(false);

  useEffect(() => {
    logUserAction(userData.contact_id, UserAction.InvestmentsPage);
  }, [])

  return (
    <Container fluid="md">
      <Switch>
        <Route exact path="/investments">
          <h1 className="bip-capital-page-header">Investments</h1>
        </Route>
        <Route
          exact
          path={"/investments/:companyId"}
          render={() => <InvestmentDetailsPage />}
        />
      </Switch>
      <Row>
        <Col lg={9}>
          <Switch>
            <Route exact path="/investments">
              <InvestmentsTable
                {...{investments, accounts, showByAccount, setShowByAccount}}
              />
              
              { bdcInvestments.length > 0 && 
                <BdcInvestmentsTable className='mb-n3' bdcInvestments={bdcInvestments}/>
              }
              <div className="mb-3">
                {!showByAccount && 
                  <div><small style={{fontSize: '11px'}}>Mouse over <em>EFV</em>, <em>IRR</em>, or <em>PRICE PER SHARE</em> row values to view As-Of dates.</small></div>
                }
                <PerformanceDisclosure/>
              </div>
              
              <CashFlowsTable investments={investments.investments ?? []} />
              {showByAccount && 
                <div className="mt-n3"><AggregateAccountDisclosure/></div>
              }
            </Route>
            <Route
              exact
              path="/investments/:companyId"
              render={({ match }) => (
                <>
                  <InvestmentsTable
                    className="mb-3"
                    {...{investments, accounts, showByAccount, setShowByAccount}}
                    companyId={match.params.companyId}
                  />
                  <div className="mt-n3 mb-3"><PerformanceDisclosure/></div>
                  <CashFlowsTable
                    investments={investments.investments ?? []}
                    accounts={accounts}
                    accountsMap={accountsMap}
                    companyId={match.params.companyId}
                  />
                  <div className="mt-n3"><AggregateAccountDisclosure/></div>
                </>
              )}
            />
            <Route
              exact
              path={"/investments/bdc/:companyId/position/:positionId"}
              render={() => <BdcPositionDetails/>}
            />
          </Switch>
        </Col>
        <Col lg={3}>
          {!!upcomingCashFlows.length && (
            <CardStack
              title="Scheduled Capital Calls"
              className="mb-3"
            >
              {upcomingCashFlows.map((cf, i) => (
                <CashFlowCard cashFlow={cf} key={i} />
              ))}
            </CardStack>
          )}

          {!!news.length && (
            <section className="bip-capital-cc-investments-news">
              <h5>
                GP Updates <Link to="/gpUpdates">See All</Link>
              </h5>
              {news.slice(0, 5).map((n, i) => (
                <NewsCard key={i} news={n} />
              ))}
            </section>
          )}
        </Col>
      </Row>
    </Container>
  );
}
