import React, { useState, createContext } from "react";

export const InvestmentsContext = createContext();

export const AppProvider = (props) => {
  const [investments, setInvestments] = useState([]);
  const [investmentsMap, setInvestmentsMap] = useState({});
  const [cashFlows, setCashFlows] = useState([]);
  const [upcomingCashFlows, setUpcomingCashFlows] = useState([]);
  const [investmentsByCompany, setInvestmentsByCompany] = useState({});
  const [investmentsByAccount, setInvestmentsByAccount] = useState({});
  const [investmentValueTotals, setInvestmentValueTotals] = useState({});

  return (
    <InvestmentsContext.Provider
      value={{
        investments,
        setInvestments,
        investmentsMap,
        setInvestmentsMap,
        cashFlows,
        setCashFlows,
        upcomingCashFlows,
        setUpcomingCashFlows,
        investmentsByCompany,
        setInvestmentsByCompany,
        investmentsByAccount,
        setInvestmentsByAccount,
        investmentValueTotals,
        setInvestmentValueTotals,
      }}
    >
      {props.children}
    </InvestmentsContext.Provider>
  );
};
