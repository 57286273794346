import React, { useState, createContext, useEffect } from "react";
import {createObjectMap} from "../utils/array.util";

export const EventsContext = createContext();

export const EventsProvider = (props) => {
  const [events, setEvents] = useState([]);
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [eventsMap, setEventsMap] = useState([]);
  const [eventTypes, setEventTypes] = useState([]);
  const [eventTypesMap, setEventTypesMap] = useState({});

  return (
    <EventsContext.Provider value={{
      events, 
      setEvents, 
      upcomingEvents, 
      setUpcomingEvents, 
      eventsMap, 
      setEventsMap,
      eventTypes,
      setEventTypes,
      eventTypesMap,
      setEventTypesMap
    }}>
      {props.children}
    </EventsContext.Provider>
  );
};
