export function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  return parts.length === 2 ? parts.pop().split(';').shift() : "";
}

export const stockImages = [
  "stock-image-1.jpg",
  "stock-image-2.jpg",
  "stock-image-3.jpg",
  "stock-image-4.jpg",
  "stock-image-5.jpg",
  "stock-image-6.jpg",
  "stock-image-7.jpg",
  "stock-image-8.jpg",
  "stock-image-9.jpg",
  "stock-image-10.jpg",
  "stock-image-11.jpg",
  "stock-image-12.jpg",
]

export function handleTokenExpireError(error) {
  if (error.name !== "TokenExpiredError") {
    return;
  }

 window.location.reload();
}

// TODO: Update to correct URL
export const TermsOfServiceUrl = "https://vision-public-assets.s3.amazonaws.com/index.html";