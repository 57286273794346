import axios from "axios";
import { useContext, useState } from "react";
import { Card } from "react-bootstrap";
import { DocumentType } from "../../utils/constants";
import { paginate } from "../../utils/array.util";
import "./ImportantDocuments.scss";
import { UserContext } from "../../context/UserContext";
import { downloadBlob } from "../../utils/file.util";
import { UIContext } from "../../context/UIContext";
import { handleTokenExpireError } from "../../utils/misc.util";

const MAX_DOCS = 3;

export const ImportantDocuments = ({documents, title = "IMPORTANT DOCUMENTS", listByFileName = false}) => {
  const {userData} = useContext(UserContext);
  const {setIsLoading, setErrorMessage} = useContext(UIContext);
  const [showAll, setShowAll] = useState(false);
  const showToggle = documents.length > MAX_DOCS;
  const isExpanded = showToggle && showAll;
  const displayedDocs = isExpanded ? documents : paginate(documents, MAX_DOCS, 1);

  const handleLinkClick = async (event, document) => {
    event.preventDefault();
    const isPPM = document.document_type_id === DocumentType.PPM;
    try {
      setIsLoading(true);
      if (isPPM) {
        const customerParam = encodeURIComponent(`${userData.first_name} ${userData.last_name}`);
        const blob = (await axios.get(`/documents/getStampedPPM/${document.id}/${customerParam}`, {responseType: 'blob'})).data;
        downloadBlob(blob, document.file_name.replace(/['"]+/g, "")); //remove potential leading/trailing quotes from file_name that may be present in migrated data
        return;
      }
  
      const link = (await axios.get(`/documents/download/${document.id}`)).data;
      window.open(link.url);
    } catch (error) {
      setErrorMessage("Error while downloading file");
      handleTokenExpireError(error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Card className={`bip-capital-important-documents ${isExpanded ? 'expanded' : ''}`}>
      <Card.Body>
        <h6>{title}</h6>
        <ul>
          {displayedDocs.map(d => 
            <li key={d.id}>
              <a href="/" onClick={(e) => handleLinkClick(e, d)}>
                {listByFileName ? d.file_name.replace(/"/g, "") : d.document_type_name}
              </a>
            </li>
          )}
        </ul>
      </Card.Body>
      { showToggle && 
        <Card.Footer>
          <span onClick={() => setShowAll(v => !v)}>See More <i className={`fas fa-caret-down ${isExpanded ? 'rotate' : ''}`}></i></span>
        </Card.Footer>
      }
    </Card>
  )
}