import React, { useState, createContext } from "react";

export const BdcInvestmentsContext = createContext();

export const AppProvider = (props) => {
  const [bdcInvestments, setBdcInvestments] = useState([]);
  const [bdcInvestmentsMap, setBdcInvestmentsMap] = useState({});

  return (
    <BdcInvestmentsContext.Provider
      value={{
        bdcInvestments,
        setBdcInvestments,
        bdcInvestmentsMap,
        setBdcInvestmentsMap
      }}
    >
      {props.children}
    </BdcInvestmentsContext.Provider>
  );
};
