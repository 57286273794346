import "./EventSignUpButton.scss";
import { useContext, useState } from "react";
import { UIContext } from "../../../context/UIContext";
import { getCookie } from "../../../utils/misc.util";
import { UserContext } from "../../../context/UserContext";
import axios from "axios";
import { logUserAction, UserAction } from "../../../utils/logging.util";

/** URL for one off stop gap to support registration for 2024 shareholders meeting */
const shareholdersEventUrl = "https://bipcapital-5706307.hs-sites.com/shareholder2024";

export const EventSignUpButton = ({event, className}) => {
  const [isRegistered, setIsRegistered] = useState(!!event.events_contacts_id);
  const {setIsLoading} = useContext(UIContext);
  const {userData} = useContext(UserContext);

  const handleClick = async () => {
    // STOP-GAP: Open hyperlink to hubspot form url for 2024 shareholders meeting
    if (event.id === +process.env.REACT_APP_2024_SHAREHOLDERS_EVENT_ID) {
      return window.open(shareholdersEventUrl, "_blank", 'noopener,noreferrer');
    }
    setIsLoading(true);
    const hubspotutk = getCookie("hubspotutk"); //Note: I don't think we can read the cookies when serving on localhost
    const event_id = event.id;
    const contact_id = userData.contact_id;
    const result = await axios.post("/events", {event_id, contact_id, hubspotutk});

    // Perhaps an antipattern to be updating a key of the prop, but saves on making an API call or updating the whole state array
    event.events_contacts_id = result.data.events_contacts_id; 
    setIsRegistered(true);
    setIsLoading(false);
    logUserAction(userData.contact_id, UserAction.RegisterForEvent, event_id);
  }

  return isRegistered 
    ? <div style= {{fontSize: "0.75em"}}>
        <span className="mr-2" style={{color: "var(--bs-success)"}}>Registered</span> 
        <i className="fas fa-check-circle" style={{color: "var(--bs-success)"}}></i>
      </div>
    : <button className={`bip-capital-event-signup-button ${className || ""}`} onClick={handleClick}>Sign Up</button>
}