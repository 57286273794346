import {Breadcrumb} from "react-bootstrap";
import {Link, useParams} from "react-router-dom";
import {useContext, useEffect, useMemo, useState, } from "react";
import axios from "axios";
import {DataTable} from "../../../components/DataTable/DataTable";
import moment from 'moment';
import {BdcInvestmentsContext} from "../../../context/BdcInvestmentsContext";
import {toCurrency} from "../../../utils/number.util";

const columnDef = [
  { id: 'posting_date', label: 'DATE', displayFn: (data) => moment(data).format('MM/DD/YYYY') },
  { id: 'transaction_type_name', label: 'TYPE' },
  { id: 'share_amount', label: 'SHARES' },
  { id: 'price_per_share', label: 'PRICE PER SHARE', displayFn: d => toCurrency(d, 2)},
  { id: 'gross_amount', label: 'AMOUNT', displayFn: d => toCurrency(d, 2)},
  { id: 'subscription_amount', label: 'SUBSCRIPTION AMOUNT', displayFn: d => toCurrency(d, 2) },
]

export const BdcPositionDetails = () => {
  const { positionId } = useParams();
  const {bdcInvestments} = useContext(BdcInvestmentsContext)
  const [transactions, setTransactions] = useState([]);
  const position = useMemo(() => bdcInvestments.find(p => p.id === +positionId), [positionId, bdcInvestments])
  const investmentName = `${position?.investment_name} - ${position?.account_name}`
  
  useEffect(() => {
    const init = async () => { 
      try {
        const {data} = await axios.get(`/bdc-investments/positions/${positionId}/transactions`);
        setTransactions(data.transactions);
      } catch (error) {
        alert('Access Forbidden')
      }
    }
    init();
  }, [])


  return (
    <>
      <div className="bip-capital-page-header">
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: "/investments"}}>Investments</Breadcrumb.Item>
          <Breadcrumb.Item active>{investmentName}</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="bip-capital-investment-detail mb-1">
        <div className="bip-capital-investment-description">
          <h4>{investmentName} Transaction History</h4>
        </div>
      </div>
      <DataTable
        columnDef={columnDef}
        dataSource={transactions}
      />
    </>
  );
};