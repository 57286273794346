import React from "react";
import { Link } from "react-router-dom";

import "./SettingsPage.css";
import Container from "../../components/Views/Container/Container";
import Rectangle from "../../components/Views/Rectangle/Rectangle";

export default function SettingsPage() {
  return (
    <div className="settings-wrapper">
      <Container>
        <Rectangle>
          <div className="Settings-title-container">
            <p className="Settings-title">Settings</p>
          </div>
          <div className="settings-content-container">
            <p className="Settings-label">Security</p>
            <Link to="/settings/updatePassword" className="Settings-link">
              Reset Password
            </Link>
            <hr />
            <p className="Settings-label">Thoughts?</p>
            <Link
              to="#"
              onClick={(e) => {
                window.location =
                  "mailto:technology@bipcapital.com?subject=BIP Capital Investor Web App Feedback";
                e.preventDefault();
              }}
              className="Settings-link"
            >
              Send Feedback
            </Link>
          </div>
        </Rectangle>
      </Container>
    </div>
  );
}
