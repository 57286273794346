import DashboardPage from "../../pages/Dashboard/DashboardPage";
import { AccountsPage } from "../../pages/Accounts/AccountsPage";
import { AccountDetail } from "../../pages/Accounts/AccountDetail/AccountDetail";
import { DocumentsPage } from "../../pages/Documents/DocumentsPage";
import { EventsPage } from "../../pages/Events/EventsPage";
import { EventDetail } from "../../pages/Events/EventDetail/EventDetail";
import InvestmentsPage from "../../pages/Investments/InvestmentsPage";
import { NewsDetail } from "../../pages/News/NewsDetail/NewsDetail";
import { NewsPage } from "../../pages/News/NewsPage";
import SettingsPage from "../../pages/Settings/SettingsPage";
import UpdatePasswordPage from "../../pages/UpdatePassword/UpdatePasswordPage";
import { SymposiumPage } from "../../pages/Symposium/SymposiumPage";
import { K1CheckListpage } from "../../pages/K1ChecList/K1CheckListPage";
import { ShareholdersPage } from "../../pages/Shareholders/ShareholdersPage";

export const authRoutes = [
  {
    path: "/",
    exact: true,
    navLinkExact: true,
    label: "Home",
    icon: "home",
    component: DashboardPage,
  },
  {
    path: "/shareholders",
    exact: true,
    icon: "shareholders-meeting",
    label: "2024 Shareholders",
    component: ShareholdersPage,
    className: "shareholders-meeting"
  },
  {
    path: "/symposium",
    exact: true,
    icon: "film",
    label: "2023 Symposium",
    component: SymposiumPage,
  },
  {
    path: "/investments",
    exact: false,
    label: "Investments",
    icon: "chart-line",
    component: InvestmentsPage,
  },
  {
    path: "/accounts",
    exact: true,
    label: "Accounts",
    icon: "file-invoice-dollar",
    component: AccountsPage,
  },
  {
    path: "/accounts/:accountId",
    exact: true,
    component: AccountDetail,
  },
  {
    path: "/events",
    exact: true,
    label: "Events",
    icon: "calendar-alt",
    component: EventsPage,
  },
  {
    path: "/events/:eventId",
    component: EventDetail,
  },
  {
    path: "/documents",
    label: "Documents",
    icon: "folder",
    component: DocumentsPage,
  },
  {
    path: "/k1checklist",
    label: "K-1 Checklist",
    icon: "file-contract",
    component: K1CheckListpage,
  },
  {
    path: "/gpUpdates",
    label: "GP Updates",
    icon: "newspaper",
    component: NewsPage,
    exact: true,
  },
  {
    path: "/gpUpdates/:newsId",
    component: NewsDetail,
  },
  {
    path: "/settings",
    exact: true,
    component: SettingsPage,
  },
  {
    path: "/settings/updatePassword",
    exact: true,
    component: UpdatePasswordPage,
  },
];
